export default function (Component) {
  const _Component = Component
  _Component.__i18n = _Component.__i18n || []
  _Component.__i18n.push({
    "locale": "",
    "resource": {
      "en-US": {
        "account__unsubscribe--title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unsubscribe"])},
        "account__unsubscribe--subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your email address below to stop receiving Nuuly marketing emails."])},
        "account__email--label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
        "global__unsubscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unsubscribe"])}
      }
    }
  })
}
