<i18n>
[
    "account__unsubscribe--title",
    "account__unsubscribe--subtitle",
    "account__email--label",
    "global__unsubscribe",
]
</i18n>

<template>
    <SlideLayout :closeLink="closeLink">
        <div class="o-row u-text--center">
            <div class="o-extra-small--12">
                <h3 class="u-spacer--1">
                    {{ $t('account__unsubscribe--title') }}
                </h3>
                <p class="u-spacer--4">
                    {{ $t('account__unsubscribe--subtitle') }}
                </p>
            </div>
            <BaseForm
                :class="[
                    'c-form',
                    'o-extra-small--12',
                    'o-medium--6',
                    'o-medium--offset-3',
                ]"
                :onSubmit="handleSubmit"
                :submitButton="{
                    text: $t('global__unsubscribe'),
                }"
                :shouldSetMinLoadTime="true"
            >
                <EmailInput
                    v-model="email"
                    class="o-extra-small--6 o-extra-small--offset-3 u-spacer--6"
                    :labelText="$t('account__email--label')"
                    :isRequired="true"
                    name="email"
                    :initialEmail="email"
                />
                <BaseSupportingTextError
                    v-if="serviceErrorMessage"
                    class="o-extra-small--6 o-extra-small--offset-3 u-spacer--6"
                    :hasError="serviceErrorMessage"
                    :errorText="serviceErrorMessage"
                />
            </BaseForm>
        </div>
    </SlideLayout>
</template>

<script>

import { get } from 'lodash-es';
import { unsubscribeEmail } from '~coreModules/profile/js/profile-api';

import SlideLayout from '~modules/core/components/layouts/SlideLayout.vue';
import BaseForm from '~coreModules/core/components/ui/forms/BaseForm.vue';
import EmailInput from '~coreModules/core/components/ui/form-elements/text-inputs/EmailInput.vue';
import BaseSupportingTextError from '~coreModules/core/components/ui/form-elements/BaseSupportingTextError.vue';

export default {
    name: 'AnonymousUnsubscribe',
    components: {
        BaseSupportingTextError,
        EmailInput,
        SlideLayout,
        BaseForm,
    },
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            if (from.path) {
                vm.setCloseLink(from.path);
            }
        });
    },
    data() {
        return {
            closeLink: '/',
            email: this.$route.query.email || '',
            serviceErrorMessage: '',
        };
    },
    methods: {
        handleSubmit() {
            return unsubscribeEmail(this.$r15Svc, this.email)
                .then(() => {
                    this.$router.push(this.closeLink);
                })
                .catch((error) => {
                    this.serviceErrorMessage = get(error, 'data.message', '');
                    this.$logger.debugError('Failed to unsubscribe user from emails: ', error);
                });
        },
        setCloseLink(newCloseLink) {
            this.closeLink = newCloseLink;
        },
    },
};
</script>

<style>

</style>
