<template>
    <BaseInput
        :id="id"
        ref="input"
        v-model="inputValue"
        :rules="{ required: true, email: true }"
        name="email"
        :isDisabled="isDisabled"
        :isRequired="isRequired"
        :tabIndex="tabIndex"
        :inputType="'email'"
        :helperText="helperText"
        :labelText="labelText"
        :supportingText="supportingText"
        :successText="successText"
        :shouldAutoFocus="shouldAutoFocus"
        :maxLength="50"
    />
</template>

<script>
import inputProps from '~coreModules/core/components/mixins/inputProps';
import formFieldProps from '~coreModules/core/components/mixins/formFieldProps';

import BaseInput from '~coreModules/core/components/ui/form-elements/text-inputs/BaseInput.vue';

export default {
    name: 'EmailInput',
    components: { BaseInput },
    mixins: [inputProps, formFieldProps],
    props: {
        initialEmail: {
            type: String,
            default: '',
        },
        isRequired: {
            type: [Boolean, String],
            default: true,
        },
        successText: {
            type: String,
            default: '',
        },
        shouldAutoFocus: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            inputValue: this.initialEmail || '',
        };
    },
    methods: {
        focus() {
            this.$refs.input.focus();
        },
        reset() {
            this.inputValue = '';
        },
    },
};
</script>

<style lang="scss">

</style>
